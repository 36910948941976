import { CmsImage, CmsLink, ModulePadding, StreamfieldBlock } from "interfaces";
import { CarouselProvider, DotGroup, Slide, Slider } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import styles from "./testimonials.module.scss";

import PullQuoteIcon from "../../public/svg/pull-quote.svg";
import { RichTextContent } from "components/RichText";
import { CmsResponsiveImage } from "components/CmsResponsiveImage";
import { OptionalCta } from "components/Clickable/Cta";
import classNames from "classnames";
import { useBlockWidth } from "components/wagtail/SplitLayoutSubPage";

export interface TestimonialSnippet {
  author: string;
  author_image?: CmsImage;
  copy: string;
  main_image?: CmsImage;
  cta_text?: string;
  cta_link?: CmsLink;
}

export type TestimonialCardBlock = StreamfieldBlock<
  "testimonial_card",
  {
    testimonial: TestimonialSnippet;
  }
>;

export interface TestimonialCardsCmsData {
  intro_copy: string;
  cards: TestimonialCardBlock[];
}

interface Props extends TestimonialCardsCmsData {
  width?: ModulePadding;
}

export type TestimonialCardsBlock = StreamfieldBlock<"testimonial_cards", TestimonialCardsCmsData>;

export type TestimonialBlock = StreamfieldBlock<"testimonial", TestimonialSnippet>;

export const TestimonialCards = ({ intro_copy, cards, width = "padded" }: Props) => {
  return (
    <section className={styles.testimonialCards}>
      <header
        className={classNames({
          [styles.cardsHeader]: true,
          [styles.padded]: width === "padded",
        })}
      >
        <RichTextContent richText={intro_copy} className={styles.richText} />
      </header>
      {/* Desktop UI */}
      <ul className={styles.desktopList}>
        {cards.map((card) => (
          <li key={card.id}>
            <Card card={card} />
          </li>
        ))}
      </ul>
      {/* Mobile UI */}
      <CardCarousel cards={cards} />
    </section>
  );
};

const Card = ({ card }: { card: TestimonialCardBlock }) => (
  <>
    <PullQuoteIcon className={styles.quoteIcon} />
    <figure className={styles.quoteFigure}>
      <blockquote
        dangerouslySetInnerHTML={{
          __html: card.value.testimonial.copy,
        }}
      ></blockquote>
      <figcaption>
        <span className={styles.author}>{card.value.testimonial.author}</span>
      </figcaption>
    </figure>
  </>
);

const CardCarousel = ({ cards }: { cards: TestimonialCardBlock[] }) => (
  <CarouselProvider
    naturalSlideWidth={213}
    naturalSlideHeight={243}
    infinite={false}
    isIntrinsicHeight={true}
    visibleSlides={1.333}
    totalSlides={cards.length}
    className={`${styles.mobileList} ${styles.carousel}`}
    step={1}
  >
    <Slider className={styles.slider} trayTag="ul" classNameTray={styles.sliderTray}>
      {cards.map((card, index) => (
        <Slide key={card.id} index={index} className={styles.slide} tag="li">
          <Card card={card} />
        </Slide>
      ))}
    </Slider>
    <DotGroup
      className={classNames({
        [styles.dotGroup]: true,
        [styles.threeDotHack]: cards.length === 3,
      })}
      showAsSelectedForCurrentSlideOnly={true}
    ></DotGroup>
  </CarouselProvider>
);

export const Testimonial = (props: TestimonialSnippet) => {
  const width = useBlockWidth();
  return (
    <section
      className={classNames({
        [styles.testimonial]: true,
        [styles.full]: width === "full",
      })}
    >
      <div className={styles.blockContentContainer}>
        <PullQuoteIcon className={styles.quoteIcon} />
        <figure className={styles.quoteFigure}>
          <blockquote
            dangerouslySetInnerHTML={{
              __html: props.copy,
            }}
          ></blockquote>
          <figcaption>
            {props.author_image && (
              <CmsResponsiveImage
                cmsImage={props.author_image}
                cldSrcSet={[120, 240, 360, 480]}
                sizes="120px"
                cloudinaryProps={{ crop: "fill", aspectRatio: "1:1" }}
              />
            )}
            <div className={styles.figcaptionText}>
              <span className={styles.author}>{props.author}</span>
              <OptionalCta
                className={styles.cta}
                cta={{ link: props.cta_link, text: props.cta_text }}
              />
            </div>
          </figcaption>
        </figure>
      </div>

      {props.main_image && (
        <div className={styles.blockImageContainer}>
          <CmsResponsiveImage
            cmsImage={props.main_image}
            cldSrcSet={[400, 600, 800, 1000, 1200, 1400, 1600]}
            sizes="(max-width: 1024px) 100vw, (min-width: 768px) 30vw"
            cloudinaryProps={{ crop: "fill", aspectRatio: "1:1" }}
          />
        </div>
      )}
    </section>
  );
};
