import classNames from "classnames";
import { StreamfieldBlock } from "interfaces";
import { Contact, NewContact, QuestionTypes } from "./Contact";
import { RequestBrochure } from "./RequestBrochure";
import { AddressChangeRequestForm } from "./AddressChangeRequestForm";
import { ReturnForm } from "./ReturnForm";

import styles from "./hardcoded-forms.module.scss";
import { useBlockWidth } from "components/wagtail/SplitLayoutSubPage";

type HardcodedFormName = "Request Brochure" | "Contact" | "Address Change Request" | "Return";

type ContactQuestionTypes = typeof QuestionTypes[number];

export interface QuestionTypeContact {
  question_type: ContactQuestionTypes;
  destination_email: string;
}

export interface HardcodedFormCmsData {
  name: HardcodedFormName;
  destination_email: string;
}

export interface NewContactHardcodedFormCmsData {
  default_destination_email: string;
  question_type_destination_emails: QuestionTypeContact[];
}

export type HardcodedFormsBlock = StreamfieldBlock<"hardcoded_form", HardcodedFormCmsData>;
export type NewContactHardcodedFormsBlock = StreamfieldBlock<
  "new_contact_hardcoded_form",
  NewContactHardcodedFormCmsData
>;

export type HardcodedFormsProps = HardcodedFormCmsData;

export type HardcodedFormProps = Omit<HardcodedFormsProps, "name">;
export const HardcodedForms = ({ name, ...props }: HardcodedFormCmsData) => {
  switch (name) {
    case "Request Brochure":
      return <RequestBrochure {...props} />;
    case "Contact":
      return <Contact />;
    case "Address Change Request":
      return <AddressChangeRequestForm {...props} />;
    case "Return":
      return <ReturnForm {...props} />;
  }
  console.error(
    `Failed to find appropriate component to render form '${name}'.`,
    "Usually this means the backend sent a form that isn't implemented in the frontend."
  );
  return null;
};

export const NewContactHardCodedForms = (props: NewContactHardcodedFormCmsData) => {
  return <NewContact {...props} />;
};

export const SuccessMessage = ({ message }: { message: string }) => {
  const width = useBlockWidth();
  return (
    <div
      className={classNames({
        [styles.form]: true,
        [styles.full]: width === "full",
      })}
    >
      <p className={styles.twelveColumns} aria-live="polite">
        {message}
      </p>
    </div>
  );
};
