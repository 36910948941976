import { standardBreakpointBuilder } from "components/Flexihero";
import {
  HeroBreakpointProps,
  HeroBreakpointSettings,
  HeroSetGroupProps,
} from "components/Flexihero/HeroBreakpoint.interfaces";
import { HeroSet } from "components/Flexihero/HeroSet";
import { StreamfieldBlock } from "interfaces";
import { BackgroundColorChoice, CmsHero, HeroMedia } from ".";

import heroStyles from "./hero-blocks.module.scss";
import { useBlockWidth } from "components/wagtail/SplitLayoutSubPage";

export interface VerticalMediaCmsData extends CmsHero {
  content_placement: "left" | "right";
  content_background_color: BackgroundColorChoice;
}

export type VerticalMediaBlock = StreamfieldBlock<"vertical_media", VerticalMediaCmsData>;

const verticalMediaSet = (
  content: HeroBreakpointProps,
  settings: HeroBreakpointSettings,
  mobileSettings: HeroBreakpointSettings
) =>
  standardBreakpointBuilder({
    desktop: {
      ...content,
      settings,
    },
    mobile: {
      ...content,
      settings: mobileSettings,
    },
  });

export const VerticalMediaHero = (props: VerticalMediaCmsData) => {
  const contentAlignSecondary = props.content_placement === "left" ? "left/top" : "right/bottom";
  const heroPlacement = useBlockWidth() === "full" ? "full" : "container";

  const desktopSettings: HeroBreakpointSettings = {
    heroPlacement,
    heroDirection: "row",
    mediaPlacement: "inline",
    contentWidth: "two-thirds",
    contentWidthCustom: "",
    contentAlignPrimary: "stretch",
    verticalMargin: "zero",
    backgroundColorPlacement: "content",
    mediaAspectRatio: "stretch",
    textAlignment: "left",
    contentAlignSecondary,
    maxHeight: 500,
    backgroundColor: "transparent",
    textColor: "inherit",
  };
  const mobileSettings: HeroBreakpointSettings = {
    ...desktopSettings,
    heroDirection: "column",
    mediaPlacement: "inline",
    mediaAspectRatio: "stretch",
    contentAlignPrimary: "middle",
    contentAlignSecondary: "right/bottom",
    contentWidth: "full",
    maxHeight: undefined,
  };

  const content: HeroBreakpointProps = {
    content: props.content || "",
    media: (
      <HeroMedia
        media={props.media}
        imageProps={{
          cldSrcSet: [620, 1240, 1860],
          sizes: "(max-width: 767px) 100vw, (min-width: 768px) 50vw, (min-width: 1440px) 620px",
          cloudinaryProps: {
            aspectRatio: "18:39",
            crop: "fill",
          },
          decorative: true,
        }}
        videoProps={{
          autoPlay: false,
          muted: true,
          control: { visibility: "hover", placement: "center" },
        }}
      />
    ),
    /** Start here, override later */
    settings: desktopSettings,
    styles: {
      outerClassName: heroStyles.verticalMediaOuter,
      contentClassName: heroStyles.verticalMediaContent,
      mediaWrapperClassName: heroStyles.verticalMedia,
    },
  };
  const set: HeroSetGroupProps = verticalMediaSet(content, desktopSettings, mobileSettings);
  return set ? <HeroSet {...set} /> : <></>;
};
