import { standardBreakpointBuilder } from "components/Flexihero";
import {
  AspectRatio,
  HeroBreakpointSettings,
  HeroPlacement,
} from "components/Flexihero/HeroBreakpoint.interfaces";
import { HeroSet } from "components/Flexihero/HeroSet";
import { StreamfieldBlock } from "interfaces";
import { CmsMedia, HeroMedia } from ".";

import heroStyles from "./hero-blocks.module.scss";

export interface FullWidthImageHeroCmsData {
  media: CmsMedia;
  width: HeroPlacement;
  aspect_ratio: AspectRatio;
  height: number | null;
  mobile_height: number | null;
}

export type FullWidthImageHeroBlock = StreamfieldBlock<
  "full_width_image",
  FullWidthImageHeroCmsData
>;

export const FullWidthImageHero = (props: FullWidthImageHeroCmsData) => {
  const baseSettings: HeroBreakpointSettings = {
    heroPlacement: props.width,
    mediaAspectRatio: props.aspect_ratio,
    minHeight: !!props.height ? props.height : undefined,
    heroDirection: "row",
    mediaPlacement: "background",
    contentWidth: "half",
    contentWidthCustom: "",
    contentAlignPrimary: "middle",
    contentAlignSecondary: "middle",
    verticalMargin: "zero",
    backgroundColorPlacement: "full",
    textAlignment: "left",
    textColor: "inherit",
    backgroundColor: "transparent",
  };

  const mobileBaseSettings: HeroBreakpointSettings = {
    ...baseSettings,
    minHeight: !!props.mobile_height ? props.mobile_height : undefined,
  };

  const aspectRatioForCloudinary = () => {
    if (props.aspect_ratio === "stretch" || props.aspect_ratio === "none") {
      return undefined;
    }
    return props.aspect_ratio
      .split(":")
      .map((number) => parseFloat(number) * 1000)
      .join(":");
  };

  const set = standardBreakpointBuilder({
    desktop: {
      content: "",
      media: (
        <HeroMedia
          media={props.media}
          imageProps={{
            cldSrcSet: [768, 1152, 1536, 1920, 2300, 3000, 3500],
            sizes: "100vw",
            cloudinaryProps: {
              aspectRatio: aspectRatioForCloudinary(),
              crop: "fill",
            },
            decorative: true,
          }}
          videoProps={{
            autoPlay: false,
            muted: false,
            respectPreferReducedMotion: true,
            control: { visibility: "hover" },
          }}
        />
      ),
      settings: {
        ...baseSettings,
        minHeight: 400,
      },
      styles: { contentClassName: heroStyles.content },
    },
    mobile: {
      content: "",
      media: (
        <HeroMedia
          media={props.media}
          imageProps={{
            cldSrcSet: [360, 720, 1080, 1440],
            sizes: "100vw",
            cloudinaryProps: {
              aspectRatio: aspectRatioForCloudinary(),
              crop: "fill",
            },
            decorative: true,
          }}
          videoProps={{
            autoPlay: false,
            respectPreferReducedMotion: true,
            control: { visibility: "hover" },
          }}
        />
      ),
      settings: mobileBaseSettings,
      styles: { contentClassName: heroStyles.content },
    },
  });
  return <HeroSet {...set} />;
};
