import classNames from "classnames";
import { ButtonPrimary } from "components/Clickable/Buttons";
import { OptionalCta } from "components/Clickable/Cta";
import { AlignedRichText, AlignedRichTextCmsData } from "components/RichText";
import { CmsCtaOptional, StreamfieldBlock } from "interfaces";
import { ProductGridTile } from "interfaces/products";
import { ProductGridTile as ProductGridTileComponent } from "../ProductGrid";

import styles from "./cross-sell.module.scss";
import { useBlockWidth } from "components/wagtail/SplitLayoutSubPage";

export type CrossSellProduct = StreamfieldBlock<"tile", ProductGridTile>;

export interface CrossSellCmsData {
  header_content: AlignedRichTextCmsData;
  tiles: CrossSellProduct[];
  cta: CmsCtaOptional;
}

export type CrossSellBlock = StreamfieldBlock<"cross_sell", CrossSellCmsData>;

export const CrossSell = ({ header_content, tiles, cta }: CrossSellCmsData) => {
  const maxItems = 4;
  const width = useBlockWidth();
  return (
    <section
      className={classNames({
        [styles.crossSell]: true,
        [styles.full]: width === "full",
      })}
    >
      <header
        className={classNames({
          [styles.header]: true,
          [styles.headerPadded]: width === "padded",
        })}
      >
        <AlignedRichText
          alignment={header_content.alignment}
          content={header_content.content}
          background_style={header_content.background_style}
          isSubBlock={true}
        />
      </header>
      <div className={styles.container}>
        {tiles.slice(0, maxItems).map((tile) => (
          <ProductGridTileComponent key={tile.id} {...tile.value} eagerLoadImage={false} />
        ))}
      </div>
      <OptionalCta cta={cta} component={ButtonPrimary} className={styles.cta} />
    </section>
  );
};
