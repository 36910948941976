import { standardBreakpointBuilder } from "components/Flexihero";
import {
  HeroBreakpointProps,
  HeroBreakpointSettings,
  HeroSetGroupProps,
} from "components/Flexihero/HeroBreakpoint.interfaces";
import { HeroSet } from "components/Flexihero/HeroSet";
import { StreamfieldBlock } from "interfaces";
import { BackgroundColorChoice, CmsHero, HeroMedia, heroColorSettings } from ".";

import heroStyles from "./hero-blocks.module.scss";
import { useBlockWidth } from "components/wagtail/SplitLayoutSubPage";

export interface SplitHeroCmsData extends CmsHero {
  content_placement: "left" | "right";
  content_background_color: BackgroundColorChoice;
}

export type SplitHeroBlock = StreamfieldBlock<"split_hero", SplitHeroCmsData>;

const splitHeroSet = (
  content: HeroBreakpointProps,
  settings: HeroBreakpointSettings,
  mobileSettings: HeroBreakpointSettings
) =>
  standardBreakpointBuilder({
    desktop: {
      ...content,
      settings,
    },
    mobile: {
      ...content,
      settings: mobileSettings,
    },
  });

export const SplitHero = (props: SplitHeroCmsData) => {
  const contentAlignSecondary = props.content_placement === "left" ? "left/top" : "right/bottom";
  const colorSettings = heroColorSettings(props.content_background_color);
  const heroPlacement = useBlockWidth() === "full" ? "full" : "container";

  const desktopSettings: HeroBreakpointSettings = {
    heroPlacement,
    heroDirection: "row",
    mediaPlacement: "inline",
    contentWidth: "half",
    contentWidthCustom: "",
    contentAlignPrimary: "stretch",
    verticalMargin: "zero",
    backgroundColorPlacement: "content",
    mediaAspectRatio: "stretch",
    textAlignment: "left",
    contentAlignSecondary,
    minHeight: 400,
    ...colorSettings,
  };
  const mobileSettings: HeroBreakpointSettings = {
    ...desktopSettings,
    heroDirection: "column",
    mediaPlacement: "inline",
    mediaAspectRatio: "8:5",
    contentAlignPrimary: "middle",
    contentAlignSecondary: "right/bottom",
    contentWidth: "full",
    minHeight: undefined,
  };

  const content: HeroBreakpointProps = {
    /** Content is being passed through, no modification in this component */
    content: props.content || "",
    /** Split hero images work the same from hero to hero */
    media: (
      <HeroMedia
        media={props.media}
        imageProps={{
          cldSrcSet: [620, 1240, 1860],
          sizes: "(max-width: 767px) 100vw, (min-width: 768px) 50vw, (min-width: 1440px) 620px",
          cloudinaryProps: {
            aspectRatio: "8:5",
            crop: "fill",
          },
          decorative: true,
        }}
        videoProps={{
          autoPlay: false,
          muted: false,
          control: { visibility: "hover", placement: "center" },
        }}
      />
    ),
    /** Start here, override later */
    settings: desktopSettings,
    styles: {
      contentClassName: heroStyles.content,
    },
  };
  const set: HeroSetGroupProps = splitHeroSet(content, desktopSettings, mobileSettings);
  return set ? <HeroSet {...set} /> : <></>;
};
