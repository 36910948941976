// Based off of https://github.com/justinlettau/states-us
// https://github.com/justinlettau/states-us/blob/main/LICENSE

export const statesAndTerritories = [
  {
    name: "Alabama",
    abbreviation: "AL",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Alaska",
    abbreviation: "AK",
    territory: false,
    contiguous: false,
    country: "United States",
  },
  {
    name: "Alberta",
    abbreviation: "AB",
    territory: false,
    contiguous: true,
    country: "Canada",
  },
  {
    name: "American Samoa",
    abbreviation: "AS",
    territory: true,
    contiguous: false,
    country: "United States",
  },
  {
    name: "Arizona",
    abbreviation: "AZ",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Arkansas",
    abbreviation: "AR",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "British Columbia",
    abbreviation: "BC",
    territory: false,
    contiguous: true,
    country: "Canada",
  },
  {
    name: "California",
    abbreviation: "CA",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Colorado",
    abbreviation: "CO",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Connecticut",
    abbreviation: "CT",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Delaware",
    abbreviation: "DE",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Federated States Of Micronesia",
    abbreviation: "FM",
    territory: true,
    contiguous: false,
    country: "United States",
  },
  {
    name: "Florida",
    abbreviation: "FL",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Georgia",
    abbreviation: "GA",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Guam",
    abbreviation: "GU",
    territory: true,
    contiguous: false,
    country: "United States",
  },
  {
    name: "Hawaii",
    abbreviation: "HI",
    territory: false,
    contiguous: false,
    country: "United States",
  },
  {
    name: "Idaho",
    abbreviation: "ID",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Illinois",
    abbreviation: "IL",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Indiana",
    abbreviation: "IN",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Iowa",
    abbreviation: "IA",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Kansas",
    abbreviation: "KS",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Kentucky",
    abbreviation: "KY",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Louisiana",
    abbreviation: "LA",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Maine",
    abbreviation: "ME",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Manitoba",
    abbreviation: "MB",
    territory: false,
    contiguous: true,
    country: "Canada",
  },
  {
    name: "Marshall Islands",
    abbreviation: "MH",
    territory: true,
    contiguous: false,
    country: "United States",
  },
  {
    name: "Maryland",
    abbreviation: "MD",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Massachusetts",
    abbreviation: "MA",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Michigan",
    abbreviation: "MI",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Minnesota",
    abbreviation: "MN",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Mississippi",
    abbreviation: "MS",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Missouri",
    abbreviation: "MO",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Montana",
    abbreviation: "MT",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Nebraska",
    abbreviation: "NE",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Nevada",
    abbreviation: "NV",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "New Brunswick",
    abbreviation: "NB",
    territory: false,
    contiguous: true,
    country: "Canada",
  },
  {
    name: "New Hampshire",
    abbreviation: "NH",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "New Jersey",
    abbreviation: "NJ",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "New Mexico",
    abbreviation: "NM",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "New York",
    abbreviation: "NY",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Newfoundland and Labrador",
    abbreviation: "NL",
    territory: false,
    contiguous: true,
    country: "Canada",
  },
  {
    name: "North Carolina",
    abbreviation: "NC",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "North Dakota",
    abbreviation: "ND",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Northern Mariana Islands",
    abbreviation: "MP",
    territory: true,
    contiguous: false,
    country: "United States",
  },
  {
    name: "Northwest Territories",
    abbreviation: "NT",
    territory: true,
    contiguous: true,
    country: "Canada",
  },
  {
    name: "Nova Scotia",
    abbreviation: "NS",
    territory: false,
    contiguous: true,
    country: "Canada",
  },
  {
    name: "Nunavut",
    abbreviation: "NU",
    territory: true,
    contiguous: true,
    country: "Canada",
  },
  {
    name: "Ohio",
    abbreviation: "OH",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Oklahoma",
    abbreviation: "OK",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Ontario",
    abbreviation: "ON",
    territory: false,
    contiguous: true,
    country: "Canada",
  },
  {
    name: "Oregon",
    abbreviation: "OR",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Palau",
    abbreviation: "PW",
    territory: true,
    contiguous: false,
    country: "United States",
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Prince Edward Island",
    abbreviation: "PE",
    territory: false,
    contiguous: true,
    country: "Canada",
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR",
    territory: true,
    contiguous: false,
    country: "United States",
  },
  {
    name: "Quebec",
    abbreviation: "QC",
    territory: false,
    contiguous: true,
    country: "Canada",
  },
  {
    name: "Rhode Island",
    abbreviation: "RI",
    territory: false,
    contiguous: true,
    country: "United States",
  },

  {
    name: "Saskatchewan",
    abbreviation: "SK",
    territory: false,
    contiguous: true,
    country: "Canada",
  },
  {
    name: "South Carolina",
    abbreviation: "SC",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "South Dakota",
    abbreviation: "SD",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Tennessee",
    abbreviation: "TN",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Texas",
    abbreviation: "TX",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Utah",
    abbreviation: "UT",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Vermont",
    abbreviation: "VT",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Virgin Islands",
    abbreviation: "VI",
    territory: true,
    contiguous: false,
    country: "United States",
  },
  {
    name: "Virginia",
    abbreviation: "VA",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Washington",
    abbreviation: "WA",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "West Virginia",
    abbreviation: "WV",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Wisconsin",
    abbreviation: "WI",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Wyoming",
    abbreviation: "WY",
    territory: false,
    contiguous: true,
    country: "United States",
  },
  {
    name: "Yukon",
    abbreviation: "YT",
    territory: true,
    contiguous: true,
    country: "Canada",
  },
];
