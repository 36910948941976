import classNames from "classnames";
import { ListboxOption } from "@reach/listbox";
import { useState } from "react";
import { useForm, SubmitHandler, Controller, FieldError } from "react-hook-form";
import { profileDescriptionMap, ProfileDescription } from "components/Account/interfaces";
import { Checkbox } from "components/Checkbox/Checkbox";
import { ButtonPrimary } from "components/Clickable/Buttons";
import { Dropdown } from "components/Dropdown/Dropdown";
import { ErrorMessage } from "components/Input/ErrorMessage";
import { FormLabel } from "components/Input/FormLabel";
import { TextInputGroup } from "components/Input/Input";
import { basicEmailPattern } from "helpers/forms";
import { HardcodedFormProps, SuccessMessage } from ".";
import { statesAndTerritories } from "./states";
import { writeCMSDataClientSide } from "helpers/api";

import styles from "./hardcoded-forms.module.scss";
import { useBlockWidth } from "components/wagtail/SplitLayoutSubPage";

const brochureOptions = [
  // "Door Hardware & Accessories Color Brochure, 8.5x11, 88 pages",
  "Door Hardware Pocket Booklet, 4x9",
  "Cabinet & Bath Pocket Booklet, 4x9",
  "Electronic Lock Pocket Booklet, 4x9",
  "Multi Point Lock Trim Pocket Booklet, 4x9",
  "Lookbook, Volume 1",
];

interface RequestBrochureForm {
  firstName: string;
  lastName: string;
  company?: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  postalCode: string;
  email: string;
  role: string;
  brochures: string[];
}

const buildMessage = (data: RequestBrochureForm) => {
  const role = data.role as ProfileDescription;
  return `REQUEST BROCHURE FORM
Name: ${data.firstName} ${data.lastName}
Role: ${profileDescriptionMap[role]}
Company: ${data.company ? data.company : "Not given"}
Address:
${data.address1}
${data.address2 ? data.address2 : ""}
${data.city}, ${data.state} ${data.postalCode},
Email: ${data.email},
Brochures Requested: ${data.brochures.join(", ")}
`;
};

export const RequestBrochure = (props: HardcodedFormProps) => {
  const statePlaceholder = "Select a state";
  const rolePlaceholder = "Select an option";
  const requiredErrorMessage = "This field is required.";

  const {
    register,
    handleSubmit,
    getValues,
    control,
    formState: { errors },
  } = useForm<RequestBrochureForm>();
  const width = useBlockWidth();

  const [formSubmittedSucessfully, setFormSubmittedSuccessfully] = useState<string | false>(false);

  const onSubmit: SubmitHandler<RequestBrochureForm> = async (data) => {
    const msgData = {
      subject: "Brochure Request",
      send_to: props.destination_email,
      message: buildMessage(data),
    };
    const response = await writeCMSDataClientSide("POST", "/api/contact/", {}, msgData);
    if (response.ok) setFormSubmittedSuccessfully("Thank you! Your request has been submitted.");
  };

  const onBrochureCheck: (value: string, onChange: (...event: any[]) => void) => void = (
    value,
    onChange
  ) => {
    if (getValues().brochures.includes(value)) {
      onChange(getValues().brochures.filter((brochure) => brochure !== value));
    } else {
      onChange([...getValues().brochures, value]);
    }
  };

  const processError = (error: FieldError) => {
    if (error.type === "required") return requiredErrorMessage;
    if (error.type === "pattern") return error.message;
  };

  return !formSubmittedSucessfully ? (
    <form
      className={classNames({
        [styles.form]: true,
        [styles.full]: width === "full",
      })}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <TextInputGroup
        {...register("firstName", { required: true })}
        className={styles.sixColumns}
        label="First Name"
        type="text"
        errorMessage={errors.firstName && requiredErrorMessage}
      />
      <TextInputGroup
        {...register("lastName", { required: true })}
        className={styles.sixColumns}
        label="Last Name"
        type="text"
        errorMessage={errors.lastName && requiredErrorMessage}
      />
      <TextInputGroup
        {...register("company")}
        className={styles.twelveColumns}
        label="Company"
        required={false}
        type="text"
      />
      <TextInputGroup
        {...register("address1", { required: true })}
        className={styles.twelveColumns}
        label="Address 1"
        type="text"
        errorMessage={errors.address1 && requiredErrorMessage}
      />
      <TextInputGroup
        {...register("address2")}
        className={styles.twelveColumns}
        label="Address 2"
        required={false}
        type="text"
      />
      <TextInputGroup
        {...register("city", { required: true })}
        className={styles.fourColumns}
        label="City"
        type="text"
        errorMessage={errors.city && requiredErrorMessage}
      />
      <Controller
        name="state"
        control={control}
        rules={{ validate: (value) => value !== statePlaceholder }}
        defaultValue={statePlaceholder}
        render={({ field: { onChange, value } }) => (
          <Dropdown
            className={styles.fourColumns}
            label="State/Province/Territory"
            labelSize="small"
            placeholder={statePlaceholder}
            value={value}
            onChange={onChange}
            errorMessage={errors.state && requiredErrorMessage}
          >
            {statesAndTerritories.map((state) => (
              <ListboxOption key={state.abbreviation} value={state.abbreviation}>
                {state.name}
              </ListboxOption>
            ))}
          </Dropdown>
        )}
      />
      <TextInputGroup
        {...register("postalCode", { required: true })}
        className={styles.fourColumns}
        label="Postal Code"
        type="text"
        errorMessage={errors.postalCode && requiredErrorMessage}
      />
      <TextInputGroup
        {...register("email", { required: true, pattern: basicEmailPattern })}
        className={styles.sixColumns}
        label="Email Address"
        type="email"
        errorMessage={errors.email && processError(errors.email)}
      />
      <Controller
        name="role"
        control={control}
        rules={{ validate: (value) => value !== rolePlaceholder }}
        defaultValue={rolePlaceholder}
        render={({ field: { onChange, value } }) => (
          <Dropdown
            className={styles.sixColumns}
            label="What is your Role?"
            labelSize="small"
            placeholder={rolePlaceholder}
            value={value}
            onChange={onChange}
            errorMessage={errors.role && requiredErrorMessage}
          >
            {Object.entries(profileDescriptionMap).map(([key, value]) => (
              <ListboxOption key={key} value={key}>
                {value}
              </ListboxOption>
            ))}
          </Dropdown>
        )}
      />
      <div className={styles.twelveColumns}>
        <Controller
          name="brochures"
          control={control}
          rules={{ required: true }}
          defaultValue={[]}
          render={({ field: { onChange } }) => (
            <fieldset
              className={classNames({
                [styles.checkboxGroup]: true,
                [styles.invalidList]: !!errors.brochures,
              })}
            >
              <FormLabel as="legend">Brochure Options</FormLabel>
              {brochureOptions.map((option, index) => (
                <Checkbox
                  value={option}
                  key={index}
                  onChange={(event) => onBrochureCheck(event.target.value, onChange)}
                >
                  {option}
                </Checkbox>
              ))}
            </fieldset>
          )}
        />
        {errors.brochures?.type === "required" && (
          <ErrorMessage>Please select at least one brochure.</ErrorMessage>
        )}
      </div>
      <div className={styles.twelveColumns}>
        <ButtonPrimary type="submit">Request a Brochure Set</ButtonPrimary>
      </div>
    </form>
  ) : (
    <SuccessMessage message={formSubmittedSucessfully} />
  );
};
