import classNames from "classnames";
import { useState } from "react";
import { ListboxOption } from "@reach/listbox";
import VisuallyHidden from "@reach/visually-hidden";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { ButtonPrimary } from "components/Clickable/Buttons";
import { Dropdown } from "components/Dropdown/Dropdown";
import { TextAreaGroup, TextInputGroup } from "components/Input/Input";
import { HardcodedFormProps, SuccessMessage } from ".";
import Clickable from "components/Clickable";
import { writeCMSDataClientSide } from "helpers/api";

import styles from "./hardcoded-forms.module.scss";

import Plus from "../../public/svg/plus.svg";
import CloseIcon from "../../public/svg/x.svg";
import { useBlockWidth } from "components/wagtail/SplitLayoutSubPage";

const returnReasons = [
  "Customer Ordered Wrong",
  "Customer Changed Mind",
  "Order Entered Wrong",
  "Order Packed Wrong",
  "Order Shipped Wrong",
  "Order Damaged",
  "Order Double Shipped",
  "Order Taken Wrong",
  "Wrong Finish Sent",
  "Rejected Finish",
  "Rejected Other",
  "Rejected - No Issue Found",
];

interface ReturnItem {
  quantity: number;
  itemNumber: number;
  emtekSONumber: number;
  invoiceNumber: number;
  shipDate: string;
  customerPONumber: number;
  returnReason: string;
}

interface ReturnForm {
  companyName: string;
  contactName: string;
  accountNumber: number;
  phoneNumber: string;
  returnItems: (ReturnItem | null)[];
  notes?: string;
}

const buildReturnItems = (items: (ReturnItem | null)[]) => {
  return items.map((item) => {
    if (item === null) return ``;
    return `
    Quantity: ${item.quantity}
    Item Number: ${item.itemNumber}
    Emtek SO#: ${item.emtekSONumber}
    Invoice #: ${item.invoiceNumber}
    Ship Date: ${item.shipDate}
    Customer PO#: ${item.customerPONumber}
    Return Reason: ${item.returnReason}
    `;
  });
};

const buildMessage = (data: ReturnForm) => {
  return `RETURN FORM
Company Name: ${data.companyName}
Contact Name: ${data.contactName}
Account Number: ${data.accountNumber}
Phone Number: ${data.phoneNumber}
--
Return Items: ${buildReturnItems(data.returnItems)}
Notes: ${data.notes ? data.notes : ""}
`;
};

export const ReturnForm = (props: HardcodedFormProps) => {
  const [returnItemNumber, setReturnItemNumber] = useState(1);
  const requiredErrorMessage = "This field is required.";
  const returnReasonPlaceholder = "Select";

  const {
    register,
    unregister,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ReturnForm>({
    defaultValues: {},
  });

  const [formSubmittedSucessfully, setFormSubmittedSuccessfully] = useState<string | false>(false);

  const onSubmit: SubmitHandler<ReturnForm> = async (data) => {
    const msgData = {
      subject: "Return Request",
      send_to: props.destination_email,
      message: buildMessage(data),
    };
    const response = await writeCMSDataClientSide("POST", "/api/contact/", {}, msgData);
    if (response.ok) setFormSubmittedSuccessfully("Thank you! We received your return request.");
  };

  const width = useBlockWidth();

  const removeReturnItem = () => {
    const number = returnItemNumber;
    setReturnItemNumber(number - 1);
    unregister(`returnItems.${number}.quantity`);
    unregister(`returnItems.${number}.itemNumber`);
    unregister(`returnItems.${number}.emtekSONumber`);
    unregister(`returnItems.${number}.invoiceNumber`);
    unregister(`returnItems.${number}.shipDate`);
    unregister(`returnItems.${number}.customerPONumber`);
    unregister(`returnItems.${number}.returnReason`);
  };

  const renderReturnItems = () => {
    let returnItems = [];
    for (let i = 1; i <= returnItemNumber; i++) {
      returnItems.push(
        <div key={`returnItem-${i}`} className={styles.returnItem}>
          {i === returnItemNumber && i !== 1 && (
            <Clickable
              type="button"
              unstyled={true}
              onClick={() => removeReturnItem()}
              className={`${styles.close} al-returns-form__remove-item`}
            >
              <CloseIcon />
              <VisuallyHidden>Remove Item</VisuallyHidden>
            </Clickable>
          )}
          <p className={styles.twelveColumns}>Item {i}</p>
          <TextInputGroup
            {...register(`returnItems.${i}.quantity`, { required: true })}
            className={styles.fourColumns}
            label="Quantity"
            type="number"
            errorMessage={errors.returnItems?.[i]?.quantity && requiredErrorMessage}
          />
          <TextInputGroup
            {...register(`returnItems.${i}.itemNumber`, { required: true })}
            className={styles.fourColumns}
            label="Item Number"
            type="text"
            errorMessage={errors.returnItems?.[i]?.itemNumber && requiredErrorMessage}
          />
          <TextInputGroup
            {...register(`returnItems.${i}.emtekSONumber`, { required: true })}
            className={styles.fourColumns}
            label="Emtek SO#"
            type="text"
            errorMessage={errors.returnItems?.[i]?.emtekSONumber && requiredErrorMessage}
          />
          <TextInputGroup
            {...register(`returnItems.${i}.invoiceNumber`, { required: true })}
            className={styles.fourColumns}
            label="Invoice #"
            type="text"
            errorMessage={errors.returnItems?.[i]?.invoiceNumber && requiredErrorMessage}
          />
          <TextInputGroup
            {...register(`returnItems.${i}.shipDate`, { required: true })}
            className={styles.fourColumns}
            label="Ship Date"
            type="text"
            errorMessage={errors.returnItems?.[i]?.shipDate && requiredErrorMessage}
          />
          <TextInputGroup
            {...register(`returnItems.${i}.customerPONumber`, { required: true })}
            className={styles.fourColumns}
            label="Customer PO#"
            type="text"
            errorMessage={errors.returnItems?.[i]?.customerPONumber && requiredErrorMessage}
          />
          <Controller
            name={`returnItems.${i}.returnReason`}
            control={control}
            rules={{ validate: (value) => value !== returnReasonPlaceholder, required: true }}
            defaultValue="Select"
            render={({ field: { onChange, value } }) => (
              <Dropdown
                className={styles.twelveColumns}
                label="Return Reason"
                labelSize="small"
                placeholder={returnReasonPlaceholder}
                value={value}
                onChange={onChange}
                errorMessage={errors.returnItems?.[i]?.returnReason && requiredErrorMessage}
              >
                {returnReasons.map((reason) => (
                  <ListboxOption key={reason} value={reason}>
                    {reason}
                  </ListboxOption>
                ))}
              </Dropdown>
            )}
          />
        </div>
      );
    }
    return returnItems;
  };

  return !formSubmittedSucessfully ? (
    <form
      className={classNames({
        [styles.form]: true,
        [styles.full]: width === "full",
      })}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <div className={classNames(styles.twelveColumns, styles.secondaryTitle)}>
        Customer Information
      </div>
      <TextInputGroup
        {...register("companyName", { required: true })}
        className={styles.twelveColumns}
        label="Company Name"
        type="text"
        errorMessage={errors.companyName && requiredErrorMessage}
      />
      <TextInputGroup
        {...register("contactName", { required: true })}
        className={styles.twelveColumns}
        label="Contact Name"
        type="text"
        errorMessage={errors.contactName && requiredErrorMessage}
      />
      <TextInputGroup
        {...register("accountNumber", { required: true })}
        className={styles.sixColumns}
        label="Account Number"
        type="text"
        errorMessage={errors.accountNumber && requiredErrorMessage}
      />
      <TextInputGroup
        {...register("phoneNumber", { required: true })}
        className={styles.sixColumns}
        label="Phone Number"
        type="tel"
        errorMessage={errors.phoneNumber && requiredErrorMessage}
      />
      <div
        className={classNames(styles.twelveColumns, styles.secondaryTitle, styles.returnTopMargin)}
      >
        Item(s) to be Returned
      </div>
      <div className={styles.twelveColumns}>{renderReturnItems()}</div>
      <Clickable
        type="button"
        onClick={() => setReturnItemNumber(returnItemNumber + 1)}
        className={classNames(
          styles.twelveColumns,
          styles.addAnotherButton,
          "al-returns-form__add-item"
        )}
      >
        Add Another Item <Plus />
      </Clickable>
      <TextAreaGroup
        {...register("notes")}
        className={classNames(styles.twelveColumns, styles.textarea, styles.returnHalfTopMargin)}
        label="Notes (Optional)"
        errorMessage={errors.notes && requiredErrorMessage}
      />
      <div
        className={classNames(styles.twelveColumns, styles.secondaryTitle, styles.returnTopMargin)}
      >
        UPS Call Tag
      </div>
      <p className={classNames(styles.twelveColumns, styles.upsText)}>
        <Clickable
          type="button"
          href="https://row.ups.com/GuestAccess/Guest/Entry?Company=QXNzYUVtdGVr"
        >
          Click here
        </Clickable>{" "}
        for UPS Call Tag
      </p>
      <div className={styles.twelveColumns}>
        <ButtonPrimary className="al-returns-form__submit" type="submit">
          Submit
        </ButtonPrimary>
      </div>
    </form>
  ) : (
    <SuccessMessage message={formSubmittedSucessfully} />
  );
};
