import classNames from "classnames";
import Clickable from "components/Clickable";
import { ButtonText } from "components/Clickable/Buttons";
import { CmsResponsiveImage } from "components/CmsResponsiveImage";
import { HoverZoom } from "components/HoverZoom";
import { CmsCta, CmsCtaImage, CmsImage, StreamfieldBlock } from "interfaces";
import { CloudinaryImage, CloudinaryPicture, CloudinaryPictureSource } from "react-tiny-cloudinary";

import typographyStyles from "../../styles/typography.module.scss";
import styles from "./image-tiles.module.scss";
import { useBlockWidth } from "components/wagtail/SplitLayoutSubPage";

export interface ImageTilesCmsData {
  heading: string;
  heading_cta?: CmsCta;
  heading_image: CmsImage | null;
  cta_list: StreamfieldBlock<"cta_image", CmsCtaImage>[];
}

export type ImageTilesBlock = StreamfieldBlock<"image_tiles", ImageTilesCmsData>;

export const ImageTiles = (props: ImageTilesCmsData) => {
  const width = useBlockWidth();
  return (
    <section
      className={classNames({
        [styles.imageTiles]: true,
        [styles.full]: width === "full",
      })}
    >
      <header>
        <div className={styles.headerContent}>
          <h2 className={styles.heading} style={{ width: getHeadingWidth(props.heading) }}>
            {props.heading}
          </h2>
          {props.heading_cta && (
            <ButtonText cmsLink={props.heading_cta.link}>{props.heading_cta.text}</ButtonText>
          )}
        </div>

        {props.heading_image && (
          <CloudinaryPicture cldId={props.heading_image.url} type="fetch">
            <picture>
              <CloudinaryPictureSource
                cldSrcSet={[360, 720, 1080, 1440, 1800]}
                crop="fill"
                aspectRatio={"1:1"}
              >
                <source srcSet={props.heading_image.url} sizes="100vw" media="(max-width: 767px)" />
              </CloudinaryPictureSource>
              <CloudinaryPictureSource
                cldSrcSet={[610, 1220, 1830]}
                crop="fill"
                aspectRatio={"21:25"}
              >
                <source
                  srcSet={props.heading_image.url}
                  sizes="(max-width: 1024px) 50vw, (min-width: 1024px) 610px"
                  media="(min-width: 768px)"
                />
              </CloudinaryPictureSource>
              <CloudinaryImage>
                <CmsResponsiveImage
                  cmsImage={props.heading_image}
                  cldSrcSet={[610, 1220, 1830]}
                  sizes="(max-width: 767px) 100vw, (max-width: 1024px) 50vw, (min-width: 1024px) 610px"
                  cloudinaryProps={{
                    aspectRatio: "21:25",
                    crop: "fill",
                  }}
                  decorative={true}
                  className={styles.headingImage}
                />
              </CloudinaryImage>
            </picture>
          </CloudinaryPicture>
        )}
      </header>
      <ul className={styles.tileList}>
        {props.cta_list.map((listItem) => (
          <li key={listItem.id}>
            <Clickable cmsLink={listItem.value.link} className={styles.tileClickable}>
              {listItem.value.image_block && (
                <HoverZoom>
                  <CmsResponsiveImage
                    cmsImage={listItem.value.image_block}
                    cldSrcSet={[150, 295, 590, 885]}
                    sizes="(max-width: 767px) 50vw, (max-width: 1024px) 25vw, (min-width: 1024px) 295px"
                    cloudinaryProps={{
                      aspectRatio: "1:1",
                      crop: "fill",
                    }}
                    decorative={true}
                  />
                </HoverZoom>
              )}
              {listItem.value.text}
            </Clickable>
          </li>
        ))}
      </ul>
    </section>
  );
};

/**
 * The width of the heading should be the width of the widest word.
 * The `ch` unit can get us close, but on a variable width font more precision
 * is required, and letter-spacing also needs to be accounted for.
 */
const getHeadingWidth = (heading: string) => {
  /**
   * Convert heading string to array of words
   */
  const headingWordWidths = heading.split(" ").map((word) =>
    word.split("").reduce((totalLength, currentLetter) => {
      /**
       * Get the adjusted character width of the current letter.
       * Default character width is 1 if there's no match in the dict
       */
      const adjustedWidth = letterWidthAdjustments[currentLetter.toUpperCase()] || 1;
      /** Add character width to total word width */
      return totalLength + adjustedWidth;
    }, 0)
  );
  /**
   * Use the widest word. (It might not always be the word with the
   * most characters!)
   */
  const widestWordWidth = Math.max(...headingWordWidths);

  /**
   * Assuming letter-spacing is in ems and font-size is in px
   */
  const letterSpacingAdjustment =
    parseFloat(typographyStyles.headingLetterSpacing) *
    parseInt(typographyStyles.h2Size) *
    widestWordWidth;

  return `calc(${widestWordWidth}ch + ${letterSpacingAdjustment}px`;
};

/**
 * We're using a variable font width; this approximates the differences.
 * In CSS `ch` is based off of the width of the 0 character, so that's
 * why 0 is the base here.
 */
const letterWidthAdjustments: { [key: string]: number } = {
  "0": 1,
  "1": 1,
  "2": 1,
  "3": 1,
  "4": 1,
  "5": 1,
  "6": 1,
  "7": 1,
  "8": 1,
  "9": 1,
  A: 1.1,
  B: 1.07,
  C: 1.12,
  D: 1.25,
  E: 1,
  F: 0.98,
  G: 1.21,
  H: 1.24,
  I: 0.69,
  J: 0.75,
  K: 1.08,
  L: 0.9,
  M: 1.45,
  N: 1.24,
  O: 1.29,
  P: 1,
  Q: 1.29,
  R: 1.04,
  S: 0.94,
  T: 1,
  U: 1.2,
  V: 1.1,
  W: 1.59,
  X: 1.06,
  Y: 1.02,
  Z: 1.02,
};
