import classNames from "classnames";
import { ListboxOption } from "@reach/listbox";
import { useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { ButtonPrimary } from "components/Clickable/Buttons";
import { Dropdown } from "components/Dropdown/Dropdown";
import { TextAreaGroup, TextInputGroup } from "components/Input/Input";
import { HardcodedFormProps, SuccessMessage } from ".";
import { statesAndTerritories } from "./states";
import { RadioButton } from "components/RadioButton/RadioButton";
import { FormLabel } from "components/Input/FormLabel";
import { ErrorMessage } from "components/Input/ErrorMessage";
import { writeCMSDataClientSide } from "helpers/api";

import styles from "./hardcoded-forms.module.scss";
import { useBlockWidth } from "components/wagtail/SplitLayoutSubPage";

const addressChangeOptions = ["Bill to", "Ship to", "Both"];
const commercialLocation = ["Yes", "No"];
const dropShipmentRates = ["Once a month", "More than once a month"];

interface AddressChangeRequestForm {
  contactName: string;
  accountNumber: number;
  phoneNumber: string;
  addressChangeOption: string;
  oldCompanyName: string;
  oldAddress1: string;
  oldAddress2?: string;
  oldCity: string;
  oldState: string;
  oldPostalCode: number;
  newCompanyName: string;
  newAddress1: string;
  newAddress2?: string;
  newCity: string;
  newState: string;
  newPostalCode: number;
  commercialLocation: string;
  dropShipmentRate: string;
  notes?: string;
}

const buildMessage = (data: AddressChangeRequestForm) => {
  return `ADDRESS CHANGE REQUEST
Contact Name: ${data.contactName}
Account Number: ${data.accountNumber}
Phone Number: ${data.phoneNumber}
Address to Change: ${data.addressChangeOption}

Old Address:
${data.oldCompanyName}
${data.oldAddress1}
${data.oldAddress2 ? data.oldAddress2 : ""}
${data.oldCity}, ${data.oldState} ${data.oldPostalCode},
New Address:
${data.newCompanyName}
${data.newAddress1}
${data.newAddress2 ? data.newAddress2 : ""}
${data.newCity}, ${data.newState} ${data.newPostalCode},
Commercial Location? ${data.commercialLocation}
How often do you expect to request drop shipments to this address? ${data.dropShipmentRate}
Notes: ${data.notes ? data.notes : ""}
`;
};

export const AddressChangeRequestForm = (props: HardcodedFormProps) => {
  const statePlaceholder = "Select a state";
  const requiredErrorMessage = "This field is required.";
  const width = useBlockWidth();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AddressChangeRequestForm>();

  const [formSubmittedSucessfully, setFormSubmittedSuccessfully] = useState<string | false>(false);

  const onSubmit: SubmitHandler<AddressChangeRequestForm> = async (data) => {
    const msgData = {
      subject: "Address Change Requested",
      send_to: props.destination_email,
      message: buildMessage(data),
    };
    const response = await writeCMSDataClientSide("POST", "/api/contact/", {}, msgData);
    if (response.ok) setFormSubmittedSuccessfully("Thank you! Your request has been submitted.");
  };

  return !formSubmittedSucessfully ? (
    <form
      className={classNames({
        [styles.form]: true,
        [styles.full]: width === "full",
      })}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <div className={classNames(styles.twelveColumns, styles.secondaryTitle)}>
        Customer Information
      </div>
      <TextInputGroup
        {...register("contactName", { required: true })}
        className={styles.twelveColumns}
        label="Contact Name"
        type="text"
        errorMessage={errors.contactName && requiredErrorMessage}
      />
      <TextInputGroup
        {...register("accountNumber", { required: true })}
        className={styles.sixColumns}
        label="Account Number"
        type="text"
        errorMessage={errors.accountNumber && requiredErrorMessage}
      />
      <TextInputGroup
        {...register("phoneNumber", { required: true })}
        className={styles.sixColumns}
        label="Phone Number"
        type="tel"
        errorMessage={errors.phoneNumber && requiredErrorMessage}
      />
      <div className={styles.twelveColumns}>
        <Controller
          name="addressChangeOption"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <fieldset
              className={classNames({
                [styles.radioButtonGroup]: true,
                [styles.invalidList]: !!errors.addressChangeOption,
              })}
            >
              <FormLabel as="legend" className={styles.radioButtonLabel}>
                Address to Change
              </FormLabel>
              {addressChangeOptions.map((value) => (
                <RadioButton
                  key={value}
                  value={value}
                  name={"addressChangeOption"}
                  onChange={onChange}
                >
                  {value}
                </RadioButton>
              ))}
            </fieldset>
          )}
        />
        {errors.addressChangeOption?.type === "required" && (
          <ErrorMessage>{requiredErrorMessage}</ErrorMessage>
        )}
      </div>
      <div className={classNames(styles.twelveColumns, styles.secondaryTitle)}>Old Address</div>
      <TextInputGroup
        {...register("oldCompanyName", { required: true })}
        className={styles.twelveColumns}
        label="Company Name"
        type="text"
        errorMessage={errors.oldCompanyName && requiredErrorMessage}
      />
      <TextInputGroup
        {...register("oldAddress1", { required: true })}
        className={styles.twelveColumns}
        label="Address 1"
        type="text"
        errorMessage={errors.oldAddress1 && requiredErrorMessage}
      />
      <TextInputGroup
        {...register("oldAddress2")}
        className={styles.twelveColumns}
        label="Address 2 (Optional)"
        type="text"
        errorMessage={errors.oldAddress2 && requiredErrorMessage}
      />
      <TextInputGroup
        {...register("oldCity", { required: true })}
        className={styles.fourColumns}
        label="City"
        type="text"
        errorMessage={errors.oldCity && requiredErrorMessage}
      />
      <Controller
        name="oldState"
        control={control}
        rules={{ validate: (value) => value !== statePlaceholder }}
        defaultValue={statePlaceholder}
        render={({ field: { onChange, value } }) => (
          <Dropdown
            className={styles.fourColumns}
            label="State/Province/Territory"
            labelSize="small"
            placeholder={statePlaceholder}
            value={value}
            onChange={onChange}
            errorMessage={errors.oldState && requiredErrorMessage}
          >
            {statesAndTerritories.map((state) => (
              <ListboxOption key={state.abbreviation} value={state.abbreviation}>
                {state.name}
              </ListboxOption>
            ))}
          </Dropdown>
        )}
      />
      <TextInputGroup
        {...register("oldPostalCode", { required: true })}
        className={styles.fourColumns}
        label="Postal Code"
        type="text"
        errorMessage={errors.oldPostalCode && requiredErrorMessage}
      />
      <div className={classNames(styles.twelveColumns, styles.secondaryTitle)}>New Address</div>
      <div className={styles.twelveColumns}>
        <p>
          Emtek does not drop ship to residential address, job sites, or non-commercial buildings.
        </p>
        <p>Emtek reserves the right to allow or disallow shipping addresses.</p>
      </div>
      <TextInputGroup
        {...register("newCompanyName", { required: true })}
        className={styles.twelveColumns}
        label="Company Name"
        type="text"
        errorMessage={errors.newCompanyName && requiredErrorMessage}
      />
      <TextInputGroup
        {...register("newAddress1", { required: true })}
        className={styles.twelveColumns}
        label="Address 1"
        type="text"
        errorMessage={errors.newAddress1 && requiredErrorMessage}
      />
      <TextInputGroup
        {...register("newAddress2")}
        className={styles.twelveColumns}
        label="Address 2 (Optional)"
        type="text"
        errorMessage={errors.newAddress2 && requiredErrorMessage}
      />
      <TextInputGroup
        {...register("newCity", { required: true })}
        className={styles.fourColumns}
        label="City"
        type="text"
        errorMessage={errors.newCity && requiredErrorMessage}
      />
      <Controller
        name="newState"
        control={control}
        rules={{ validate: (value) => value !== statePlaceholder }}
        defaultValue={statePlaceholder}
        render={({ field: { onChange, value } }) => (
          <Dropdown
            className={styles.fourColumns}
            label="State/Province/Territory"
            labelSize="small"
            placeholder={statePlaceholder}
            value={value}
            onChange={onChange}
            errorMessage={errors.newState && requiredErrorMessage}
          >
            {statesAndTerritories.map((state) => (
              <ListboxOption key={state.abbreviation} value={state.abbreviation}>
                {state.name}
              </ListboxOption>
            ))}
          </Dropdown>
        )}
      />
      <TextInputGroup
        {...register("newPostalCode", { required: true })}
        className={styles.fourColumns}
        label="Postal Code"
        type="text"
        errorMessage={errors.newPostalCode && requiredErrorMessage}
      />
      <div className={styles.twelveColumns}>
        <Controller
          name="commercialLocation"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <fieldset
              className={classNames({
                [styles.radioButtonGroup]: true,
                [styles.invalidList]: !!errors.commercialLocation,
              })}
            >
              <FormLabel as="legend" className={styles.radioButtonLabel}>
                Is this a commercial location?
              </FormLabel>
              {commercialLocation.map((value) => (
                <RadioButton
                  key={value}
                  value={value}
                  name="commercialLocation"
                  onChange={onChange}
                >
                  {value}
                </RadioButton>
              ))}
            </fieldset>
          )}
        />
        {errors.commercialLocation?.type === "required" && (
          <ErrorMessage>{requiredErrorMessage}</ErrorMessage>
        )}
      </div>
      <div className={styles.twelveColumns}>
        <Controller
          name="dropShipmentRate"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <fieldset
              className={classNames({
                [styles.radioButtonGroup]: true,
                [styles.invalidList]: !!errors.dropShipmentRate,
              })}
            >
              <FormLabel as="legend" className={styles.radioButtonLabel}>
                How often do you expect to request drop shipments to this address?
              </FormLabel>
              {dropShipmentRates.map((value) => (
                <RadioButton
                  key={value}
                  value={value}
                  name={"dropShipmentRate"}
                  onChange={onChange}
                >
                  {value}
                </RadioButton>
              ))}
            </fieldset>
          )}
        />
        {errors.dropShipmentRate?.type === "required" && (
          <ErrorMessage>{requiredErrorMessage}</ErrorMessage>
        )}
      </div>
      <TextAreaGroup
        {...register("notes")}
        className={classNames(styles.twelveColumns, styles.textarea)}
        label="Notes (Optional)"
        errorMessage={errors.notes && requiredErrorMessage}
      />
      <div className={styles.twelveColumns}>
        <ButtonPrimary type="submit">Submit</ButtonPrimary>
      </div>
    </form>
  ) : (
    <SuccessMessage message={formSubmittedSucessfully} />
  );
};
